import React from "react"
import { Container } from "react-bootstrap"
import CustomSlider from "../CustomSlider/CustomSlider"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import PropertySliderCard from "../PropertySliderCard/PropertySliderCard"
import "./PropertySlider.scss"
import { Link } from "gatsby"

const PropertySlider = ({filteredProperty }) => {
  const {isMobile} = useDeviceMedia()
  return (
    <div>
         {filteredProperty?.length > 0 && (
        <div className="landing_page-property-wrapper">
          <Container>
            <div className="landing_page-property-card">
              <div className="landing_page-property-title">
                <h2 className="landing_page-prop-title">Latest Properties</h2>
                <div className="all-offplan-landing">
                  <Link to="/properties/for-sale/in-dubai/">VIEW ALL</Link>
                </div>
              </div>
              <CustomSlider
                showArrows
                iconchange
                className="landing_page-prop-slider"
                slidecount={isMobile?1:4}
              >
                {filteredProperty.length > 0 &&
                  filteredProperty.map((item, index) => {
                    const imageUrl =
                      item?.node?.images?.length > 0 &&
                      item.node?.images[0]

                    const imageLength = item.node?.images?.length

                    return (
                      <PropertySliderCard
                        title={item.node?.title}
                        image={imageUrl}
                        imageCount={imageLength}
                        imageList={item.node?.images}
                        display_address={item.node?.display_address}
                        price={item.node?.price}
                        bathroom={item.node?.bathroom}
                        bedroom={item.node?.bedroom}
                        floorarea_min={item.node?.floorarea_min}
                        floorarea_type={item.node?.floorarea_type}
                        search_type={item.node?.search_type}
                        department={item.node?.department}
                        strapi_id={item?.node?.strapi_id}
                        slug={item.node?.slug}
                        crm_id={item.node?.crm_id}
                        status={item.node?.status}
                        imagetransforms={item.node?.imagetransforms}
                        slider_name="landing_page-property-slider"
                        ggfx_results={item.node?.ggfx_results}
                      />
                    )
                  })}
              </CustomSlider>
            </div>
          </Container>
        </div>
      )}
    </div>
  )
}

export default PropertySlider